.game {
    width: 600px;
    height: 225px;
    border: 1px solid black;
    margin: auto;
  }
  
  #dino {
    width: 50px;
    height: 50px;
    background-image: url('https://play-lh.googleusercontent.com/iiIJq5JmLFYNI1bVz4IBHyoXs508JcEzHhOgau69bnveF9Wat51-ax9LMPVOlneKwqg');
    background-size: 50px 50px;
    position: relative;
    top: 150px;
  }
  
  .jump {
    animation: jump 0.3s linear;
  }
  
  @keyframes jump {
    0% {
      top: 150px;
    }
  
    30% {
      top: 130px;
    }
  
    50% {
      top: 80px;
    }
  
    80% {
      top: 130px;
    }
  
    100% {
      top: 150px;
    }
  }
  
  #cactus {
    width: 20px;
    height: 40px;
    position: relative;
    top: 110px;
    left: 580px;
    background-image: url("https://w7.pngwing.com/pngs/380/807/png-transparent-game-off-game-jam-gamedev-net-video-game-cactus-game-angle-text-thumbnail.png");
    background-size: 20px 40px;
    animation: block 1s infinite linear;
  }
  
  @keyframes block {
    0% {
      left: 580px;
    }
  
    100% {
      left: -5px;
    }
  }